import React, { useContext, useEffect, useState } from 'react';
import { Formik } from 'formik';
import * as yup from 'yup';

import Loader from "react-loader-spinner";


import {
  Button,
  CompanyFormContainer,
  ElementWithButtonContainer,
  Error,
  GridItemContainer,
  SignUpFormField,
  SignUpFormFieldLabel,
  SignUpFormikField,
  SignUpFormikForm,
  SignUpFormMaskedField,
  SimpleGrid,
  Title,
  TitleContainer
} from '../../styles';
import api from '../../../../services/api';
import AuthContext from '../../../../contexts/authContext';
import { Fragment } from 'react';
import LoadingModal from '../../../../components/loadingmodal';
import ResponseModal from '../../../../components/responsemodal';
import StoragedList from '../../../../storage/list';
import FormContext from '../../../../contexts/formContext';
import ApiConfigContext from '../../../../contexts/apiConfigContext';
import LabelledButton from '../../../../components/labelledbutton';
import Modal from '../../../../components/modal';
import GeneralUtils from '../../../../utils';
import CloseButton from '../../../../components/closeButton';
import ButtonIcon from '../../../../components/buttonicon';
import UrlTestButton from '../../../../components/urltestbutton';

const CustomInput = props => (
  <SignUpFormMaskedField
    {...props}
    mask="99.999.999/9999-99"
    maskChar={null}
  />
)

function CompanySignUpForm({
  group,
  licensedApps,
  companyToUpdate,
  closeModal,
  prevPageReload,
}) {

  const [base64, setBase64] = useState(null);
  const [selectedGroupApps, setSelectedGroupApps] = useState([]);
  const [groupList, setGroupList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isSuccessModalVisible, setIsSucessModalVisible] = useState(false);
  const [isRequestErrorModalVisible, setIsRequestErrorModalVisible] = useState(false);
  const [isGroupDropdownVisible, setIsGroupDropdownVisible] = useState(false);
  const [conflictError, setConflictError] = useState(null);

  const [prevPageHasToReload, setPrevPageHasToReload] = useState(false);

  const [apiUrls, setApiUrls] = useState({ urlSarApp: '', urlHKPonto: '', urlHKRonda: '', urlHKSpr: '', urlHKClean: '' });


  const { token, checkTokenValidation } = useContext(AuthContext);
  const { apiUrl } = useContext(ApiConfigContext);
  const { clearCompanySelection } = useContext(FormContext);



  useEffect(() => {
    initForm();
    if (companyToUpdate) {
      setBase64(companyToUpdate.logo);
    }

    return () => {
      clearCompanySelection();
    }
  }, []);


  async function initForm() {

    if (companyToUpdate && companyToUpdate.urls) {
      let apiUrlsCurrentValues = {};
      for (let i of companyToUpdate.urls) {
        apiUrlsCurrentValues[`url${i.descricao}`] = i.url;
      }
      setApiUrls(apiUrlsCurrentValues);
    }

    showUrlInputs(licensedApps);

  }

  async function loadGroups() {
    const storagedList = StoragedList.getList();
    if (storagedList) {
      setGroupList(storagedList);
      return;
    }

    const config = {
      headers: {
        Authorization: `Bearer ${token}`
      }
    }


    try {
      setIsLoading(true);
      const response = await api.directTo(apiUrl).get('/getAllGroups', config);
      setGroupList(response.data.data);
    } catch {
      return;
    } finally {
      setIsLoading(false);
    }
  }


  const showUrlInputs = (licensedApps, clearUrlValues) => {
    setSelectedGroupApps(licensedApps.filter(app => app.aplicativo != 4));

    if (clearUrlValues) {
      let cleanUrlValues = {};
      for (let app of group.apps) {
        cleanUrlValues[`url${app.descricao}`] = '';
      }

      setApiUrls({ ...cleanUrlValues });
    }
  }

  const handleUrlInputsChange = (app, value) => {
    const newApiUrls = apiUrls;
    newApiUrls[app] = value;
    setApiUrls({ ...newApiUrls });
  }

  const handleSubmit = async (values) => {

    if (!checkTokenValidation()) {
      return;
    }

    const keys = Object.keys(apiUrls);
    const newObjectUrls = {};

    for (let i = 0; i < keys.length; i++)  newObjectUrls[keys[i]] = apiUrls[keys[i]].trim();


    const data = {
      codigoempresa: values.companyCode.toString(),
      nomefantasia: values.tradeName,
      cnpj: values.cnpj.replace(/[^\d]+/g, ''),
      razaosocial: values.companyName,
      grupoempresa: parseInt(values.companyGroup),
      logo: base64,
      ...newObjectUrls
    }


    try {
      const config = {
        headers: {
          Authorization: `Bearer ${token}`
        }
      }


      const route = companyToUpdate ? '/updateCompany' : '/setCompany';
      const response = await api.directTo(apiUrl).post(route, data, config);
      if (response.data.status === 0) {
        await showSuccessModal('success');

        if (companyToUpdate) {
          StoragedList.updateCompany({ ...data, originalGroup: companyToUpdate.grupo });
        } else {
          StoragedList.setCompany(data);
        }

        setPrevPageHasToReload(true);

        return true;
      } else if (response.data.status === 55 || response.data.status === 47) {
        showConflictErrorModal('CNPJ');
        return false;
      } else if (response.data.status === 46) {
        showConflictErrorModal('código de empresa');
        return false;
      } else {
        await showRequestErrorModal('error');
        return false;
      }

    } catch (e) {
      alert(e);
      await showRequestErrorModal('error');
      return;
    }
  }


  async function showSuccessModal() {
    setIsSucessModalVisible(true);
    await new Promise(resolve => setTimeout(resolve, 2000));
    setIsSucessModalVisible(false);
  }

  async function showRequestErrorModal() {
    setIsRequestErrorModalVisible(true);
    await new Promise(resolve => setTimeout(resolve, 2000));
    setIsRequestErrorModalVisible(false);
  }

  async function showConflictErrorModal(attribute) {
    setConflictError(attribute);
    await new Promise(resolve => setTimeout(resolve, 2000));
    setConflictError(null);
  }


  function handleClose() {
    closeModal();
    if (prevPageHasToReload && prevPageReload) prevPageReload();
  }


  async function showGroupSelection() {
    await loadGroups();
    setIsGroupDropdownVisible(true);
  }

  const companySchema = yup.object().shape({
    companyCode: yup
      .number()
      .required("Campo não pode ficar vazio."),
    companyName: yup
      .string()
      .required("Campo não pode ficar vazio."),
    tradeName: yup
      .string()
      .required("Campo não pode ficar vazio."),
    companyGroup: yup
      .string()
      .required("Campo não pode ficar vazio."),
    cnpj: yup
      .string()
      .min(14, "Formato incorreto")
      .test("test-valid", "CNPJ inválido", (value) => GeneralUtils.validateCNPJ(value))
      .required("Campo não pode ficar vazio."),
  })

  const companyClearValues = {
    companyCode: '',
    companyName: '',
    tradeName: '',
    companyGroup: group ? group.grupoEmpresa : '',
    cnpj: '',
    logo: '',
  }

  const companyPreFilledValues = {
    companyCode: companyToUpdate ? companyToUpdate.codigoEmpresa : '',
    companyName: companyToUpdate ? companyToUpdate.razaoSocial : '',
    tradeName: companyToUpdate ? companyToUpdate.nomeFantasia : '',
    companyGroup: companyToUpdate ? group.grupoEmpresa : '',
    cnpj: companyToUpdate ? companyToUpdate.cnpj : '',
    logo: '',
  }


  return (
    <Modal bigger>
      <CloseButton handleClick={handleClose} />


      {!isGroupDropdownVisible &&
        <ElementWithButtonContainer>
          <Title>
            {group.grupo}
          </Title>
          <ButtonIcon
            src="assets/edit.png"
            onClick={showGroupSelection}
          />
        </ElementWithButtonContainer>}




      {isLoading &&
        <LoadingModal />}


      {isSuccessModalVisible &&
        <ResponseModal
          icon='checked'
          response={`Item ${companyToUpdate ? 'alterado' : 'cadastrado'} com sucesso.`}
        />
      }

      {isRequestErrorModalVisible &&
        <ResponseModal
          icon='sad'
          response='Algo deu errado. Tente novamente.'
        />
      }

      {conflictError &&
        <ResponseModal
          icon='sad'
          response={`Esse ${conflictError} já existe no sistema.`}
        />}

      <Formik
        initialValues={companyToUpdate ? companyPreFilledValues : companyClearValues}

        validationSchema={companySchema}
        validateOnChange={false}
        validateOnBlur={false}

        onReset={() => {
          clearCompanySelection();
          setApiUrls({ urlSarApp: '', urlHKPonto: '', urlHKRonda: '', urlHKSpr: '', urlHKClean: '' });
        }}

        onSubmit={async (values, { resetForm }) => {
          let submitted = await handleSubmit(values);
          if (submitted) resetForm({ values: companyClearValues })
        }}
      >
        {({ isSubmitting, errors, setFieldValue, values, setErrors, resetForm }) => (
          <CompanyFormContainer
            role='form'
          >
            {false &&
              <LabelledButton
                type="reset"
                src="assets/add.png"
                label="Empresa"
                handleClick={() => resetForm({ values: companyClearValues })}
                absolute
                align={'right'}
              />}

            {isGroupDropdownVisible &&
              <>
                <SignUpFormFieldLabel htmlFor="companyGroup">Grupo</SignUpFormFieldLabel>

                <SignUpFormikField
                  as='select'
                  id='companyGroup'
                  value={values.companyGroup}
                  name='companyGroup'
                  haserrors={errors.companyGroup}
                  onChange={event => {
                    if (errors.companyGroup)
                      setErrors({ ...errors, companyGroup: null });

                    setFieldValue('companyGroup', event.target.value);

                    const newSelectedGroup = groupList.find(group => parseInt(group.grupoEmpresa) === parseInt(event.target.value));
                    showUrlInputs(newSelectedGroup.apps, true);
                  }
                  }
                >
                  <option
                    disabled
                    value=""
                    key="0"
                  >
                    Selecione o grupo
                  </option>

                  {groupList.map(group => (
                    <option
                      value={group.grupoEmpresa}
                      key={group.grupoEmpresa}
                    >
                      {group.grupo}
                    </option>
                  ))}

                </SignUpFormikField>

              </>}



            <SignUpFormFieldLabel htmlFor="companyName">Razão Social</SignUpFormFieldLabel>
            <SignUpFormikField
              type='text'
              id='companyName'
              name='companyName'
              maxLength={80}
              onChange={event => {
                if (errors.companyName)
                  setErrors({ ...errors, companyName: null })

                setFieldValue('companyName', event.target.value)
              }}
              haserrors={errors.companyName}
            />
            {errors.companyName ? <Error>{errors.companyName}</Error> : null}

            <SignUpFormFieldLabel htmlFor="tradeName">Nome fantasia</SignUpFormFieldLabel>
            <SignUpFormikField
              type='text'
              id='tradeName'
              name='tradeName'
              maxLength={40}
              onChange={event => {
                if (errors.tradeName)
                  setErrors({ ...errors, tradeName: null })

                setFieldValue('tradeName', event.target.value)
              }}
              haserrors={errors.tradeName}
            />
            {errors.tradeName ? <Error>{errors.tradeName}</Error> : null}


            <SimpleGrid>
              <GridItemContainer>
                <SignUpFormFieldLabel htmlFor="companyCode">Código da empresa</SignUpFormFieldLabel>
                <SignUpFormikField
                  id='companyCode'
                  name='companyCode'
                  disabled={!!companyToUpdate}
                  onChange={event => {
                    if (errors.companyCode)
                      setErrors({ ...errors, companyCode: null })

                    const value = event.target.value;
                    if (isNaN(value) || (value < 1 && value != "")) return;

                    setFieldValue('companyCode', event.target.value);
                  }}
                  haserrors={errors.companyCode}
                />
                {errors.companyCode ? <Error>{errors.companyCode}</Error> : null}
              </GridItemContainer>

              <GridItemContainer>
                <SignUpFormFieldLabel htmlFor="cnpj">CNPJ:</SignUpFormFieldLabel>
                <SignUpFormikField
                  type='text'
                  id='cnpj'
                  name='cnpj'
                  haserrors={errors.cnpj}
                  onChange={event => {
                    if (errors.cnpj)
                      setErrors({ ...errors, cnpj: null })

                    setFieldValue('cnpj', event.target.value);
                  }}
                  value={values.cnpj}
                  as={CustomInput}
                />

                {errors.cnpj ? <Error>{errors.cnpj}</Error> : null}
              </GridItemContainer>
            </SimpleGrid>


            {selectedGroupApps.map(app => (
              <Fragment key={app.aplicativo}>

                <SignUpFormFieldLabel
                  htmlFor={`url${app.descricao}`}
                >
                  URL {app.descricao}
                </SignUpFormFieldLabel>

                <ElementWithButtonContainer>
                  <SignUpFormField
                    value={apiUrls[`url${app.descricao}`]}
                    onChange={event => handleUrlInputsChange(`url${app.descricao}`, event.target.value)}
                    id={`url${app.descricao}`}
                  />

                  <UrlTestButton
                    url={apiUrls[`url${app.descricao}`]}
                    width={'100px'}
                  />
                </ElementWithButtonContainer>

              </Fragment>
            ))}


            <Button
              type="submit"
              aria-label='save-company'
              align={'flex-start'}
            >
              {isSubmitting ?
                <Loader
                  type="ThreeDots"
                  color="#00BFFF"
                  height={10}
                  width={20}
                />
                : 'SALVAR'}
            </Button>



          </CompanyFormContainer>
        )}

      </Formik>
    </Modal>
  )
}


export default CompanySignUpForm;