import React, { useContext, useRef, useState } from 'react';


import {
  AddLink,
  Button,
  Error,
  PlaceholderSquare,
  SignUpForm,
  SignUpFormField,
  SignUpFormFieldLabel,
  BoxImageGroup,
  SectionBGSarApp,
  InputImage,
  FileLink,
  RowSection,
  SimpleGrid,
  IconButton,
  SelectedAppList,
  SpecialInputContainer,
  TableTitle,
  Divisor,
  Divider,
  ElementWithButtonContainer,
  GridItemContainer,
  LogoBox,
} from '../../styles';

import Loader from "react-loader-spinner";

import { Overlay } from '../overlay/styles';
import SelectedApp from '../selectedApp';
import AppSelectionModal from '../appselectionmodal';
import { useEffect } from 'react';
import api from '../../../../services/api';
import AuthContext from '../../../../contexts/authContext';
import Format from '../../../../utils/format';
import StoragedList from '../../../../storage/list';
import AppExclusionModal from '../../../../components/appexclusionmodal';
import FormContext from '../../../../contexts/formContext';
import ApiConfigContext from '../../../../contexts/apiConfigContext';
import Utils from "../../../../utils"
import LabelledButton from '../../../../components/labelledbutton';
import GroupPicker from '../../../../components/grouppicker';
import { AiOutlineSearch } from 'react-icons/ai';
import CompanyTable from '../../../../components/companytable';
import CompanySignUpForm from '../companysignupform';
import GeneralUtils from '../../../../utils';
import LoadingModal from '../../../../components/loadingmodal';
import UsersSefipTable from "../../../../components/userssefiptable"
import UserSefipForm from '../userSefipForm';
import requests from '../../../../services/requests';
import ConfirmDialog from '../../../../components/confirmdialog';
import { useHistory, useLocation } from 'react-router';

function GroupSignUpForm({ groupToUpdate, showModal, handleNewGroupInsertion }) {
  const apps = [
    { aplicativo: '1', descricao: 'SarApp' },
    { aplicativo: '2', descricao: 'HKPonto' },
    { aplicativo: '3', descricao: 'HKRonda' },
    { aplicativo: '4', descricao: 'Portal do cliente' },
    { aplicativo: '5', descricao: 'HKSpr' },
    { aplicativo: '6', descricao: 'Ponto Pessoal' },
    { aplicativo: '7', descricao: 'HKClean' }


  ];

  const history = useHistory();

  const [currentGroup, setCurrentGroup] = useState(groupToUpdate);

  const [licensedApps, setLicensedApps] = useState([]);

  const [groupName, setGroupName] = useState('');
  const [groupCode, setGroupCode] = useState('');
  const [clientSiteUrl, setClientSiteUrl] = useState(null);

  const [appOptions, setAppOptions] = useState(apps);
  const [selectedApps, setSelectedApps] = useState([]);
  const [companies, setCompanies] = useState([]);
  const [base64, setBase64] = useState(null);
  const [base64Logo, setBase64Logo] = useState("");
  const [errors, setErrors] = useState({
    groupName: false,
    apps: false,
  });


  const [appToRemove, setAppToRemove] = useState(null);
  const [isAppModalVisible, setIsAppModalVisible] = useState(false);
  const [isGroupPickerVisible, setIsGroupPickerVisible] = useState(false);
  const [isCompanySignUpFormVisible, setIsCompanySignUpFormVisible] = useState(false);

  const [appToEdit, setAppToEdit] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isSaveButtonVisible, setIsSaveButtonVisible] = useState(false);

  const { token, checkTokenValidation } = useContext(AuthContext);
  const { apiUrl } = useContext(ApiConfigContext);
  const { clearGroupSelection, companyToUpdate, selectCompany, selectGroup } = useContext(FormContext);
  const logoInputRef = useRef();
  const [userSefip, setUserSefip] = useState({ show: false, userData: null });
  const [allUseresSefip, setAllUsersSefip] = useState([]);
  const [showDialogConfirm, setShowDialogConfirm] = useState({ show: false, title: '', message: '', handleConfirm: null, handleCancel: null })
  const refCodeCompanyOrGroupToDelete = useRef(null);

  const config = {
    headers: {
      Authorization: `Bearer ${token}`
    }
  }

  useEffect(() => {
    getUsersSefip();
  }, [])

  useEffect(() => {
    initForm();
  }, [groupToUpdate]);



  async function getUsersSefip() {
    if (currentGroup && currentGroup.grupo_code) {
      const response = await requests.getUsersSefip(currentGroup.grupo_code);
      if (!response.error) setAllUsersSefip(response.data.usuarios)
    }
  }


  function initForm() {
    if (groupToUpdate) {
      let newSelectedApps = groupToUpdate.apps;
      let optionsToRemove = [];

      setLicensedApps([...newSelectedApps]);

      for (let selectedApp of newSelectedApps) {
        selectedApp.dataValidade = Format.pureDateToYMD(selectedApp.dataValidade);
        optionsToRemove.push(selectedApp.aplicativo);
      }

      setSelectedApps([...newSelectedApps]);
      setGroupName(groupToUpdate.grupo);
      setGroupCode(groupToUpdate.grupo_code);
      setClientSiteUrl(groupToUpdate.urlPortalCliente);
      setBase64(groupToUpdate.imageSarApp);
      setCompanies(groupToUpdate.empresas);
      setBase64Logo(groupToUpdate.logo)

      let newAppOptions = appOptions.filter(option => !optionsToRemove.includes(parseInt(option.aplicativo)));
      setAppOptions([...newAppOptions])
    } else {
      setIsSaveButtonVisible(true);
    }
  }


  /*
    METODOS PARA DELETAR EMPRESA
  */

  function deleteCompany(codeEmpresa, event) {
    event.stopPropagation();
    refCodeCompanyOrGroupToDelete.current = codeEmpresa;
    setShowDialogConfirm({
      show: true,
      message: 'Deseja realmente excluir essa empresa?',
      title: 'Exclusão de Empresa',
      handleCancel: handleCancelDeleteCompany,
      handleConfirm: handleDeleteCompany
    })
  }


  async function handleDeleteCompany() {
    setShowDialogConfirm({ show: false, message: '', title: '', handleCancel: null, handleConfirm: null });
    setIsLoading(true)
    try {
      const response = await requests.deleteCompany(refCodeCompanyOrGroupToDelete.current);
      if (response.error) return alert(`Não foi possível excluir empresa: ${response.mensagem}`);
      refCodeCompanyOrGroupToDelete.current = null;
      showModal('success');
      reloadCompanies();
    } catch (error) {
      throw new Error("Erro ao excluir empresa")
    } finally {
      setIsLoading(false)
    }
  }


  function handleCancelDeleteCompany() {
    refCodeCompanyOrGroupToDelete.current = null;
    setShowDialogConfirm({
      show: false,
      message: '',
      title: '',
      handleCancel: null,
      handleConfirm: null
    })
  }
  /*
    FIM  METODOS PARA DELETAR EMPRESA
  */




  /*
    METODOS PARA DELETAR GRUPO
  */

  function deleteGrupo() {
    if (!groupCode) {
      return alert('Grupo sem código cadastrado. Por favor, cadastre o código do grupo, salve e tente novamente')
    }
    refCodeCompanyOrGroupToDelete.current = groupCode;
    setShowDialogConfirm({
      show: true,
      message: 'Deseja realmente excluir esse grupo',
      title: 'Exclusão de Grupo',
      handleCancel: handleCancelDeleteCompany,
      handleConfirm: handleDeleteGrupo
    })
  }


  async function handleDeleteGrupo() {
    setShowDialogConfirm({ show: false, message: '', title: '', handleCancel: null, handleConfirm: null });
    setIsLoading(true)
    try {
      const response = await requests.deleteGrupo(refCodeCompanyOrGroupToDelete.current);
      if (response.error) return alert(`Não foi possível excluir grupo: ${response.mensagem}`);
      refCodeCompanyOrGroupToDelete.current = null;
      history.push("/");
      showModal('success');
    } catch (error) {
      throw new Error("Erro ao excluir grupo")
    } finally {
      setIsLoading(false)
    }
  }


  function handleCancelDeleteCompany() {
    refCodeCompanyOrGroupToDelete.current = null;
    setShowDialogConfirm({
      show: false,
      message: '',
      title: '',
      handleCancel: null,
      handleConfirm: null
    })
  }






  function handleNameChange(value) {
    setErrors({ ...errors, groupName: false })
    if (groupToUpdate && value !== currentGroup.grupo) {
      setIsSaveButtonVisible(true);
    } else if (groupToUpdate && value === currentGroup.grupo) {
      setIsSaveButtonVisible(false);
    }

    setGroupName(value);
  }

  function handleGroupCodeChange(value) {
    if (isNaN(value) || (value < 1 && value !== "")) {
      setGroupCode(groupCode);
      return;
    }
    setErrors({ ...errors, groupCode: false })



    if (groupToUpdate && value !== currentGroup.grupo_code) {
      setIsSaveButtonVisible(true);
    } else if (groupToUpdate && value === currentGroup.grupo_code) {
      setIsSaveButtonVisible(false);
    }

    setGroupCode(value);
  }

  function handleClientSiteUrlChange(value) {
    if (groupToUpdate && value !== currentGroup.urlPortalCliente) {
      setIsSaveButtonVisible(true);
    } else if (groupToUpdate && value === currentGroup.urlPortalCliente) {
      setIsSaveButtonVisible(false);
    }
    setClientSiteUrl(value)
  }


  function handleAppModalDisplay() {
    if (isAppModalVisible) {
      setAppToEdit(null);
    }

    setIsAppModalVisible(!isAppModalVisible);
  }


  function handleAppAdd(selectedApp, expirationDate) {
    const newSelectedApp = {
      ...selectedApp,
      dataValidade: expirationDate,
    }

    setSelectedApps([...selectedApps.concat(newSelectedApp)]);
    setAppOptions([...appOptions.filter(option => option.aplicativo !== selectedApp.aplicativo)]);
    setErrors({ ...errors, apps: false });

    if (!isSaveButtonVisible) {
      setIsSaveButtonVisible(true);
    }
  }



  function handleAppUpdate(app) {
    const newSelectedAppsArray = selectedApps.map(selectedApp => {
      if (selectedApp.aplicativo === app.aplicativo) {
        return app;
      }
      return selectedApp;
    });

    if (!isSaveButtonVisible) {
      setIsSaveButtonVisible(true);
    }

    setSelectedApps([...newSelectedAppsArray]);
  }



  function handleAppEdit(app) {
    setAppToEdit(app);
    setIsAppModalVisible(true);
  }



  async function handleGroupSubmit() {
    if (!checkTokenValidation()) {
      return;
    }



    if (areValuesValid()) {
      setIsSubmitting(true);
      const route = groupToUpdate ? '/updateGroup' : '/setGroup';
      const groupData = groupToUpdate ?
        {
          groupName,
          grupoEmpresa: groupToUpdate.grupoEmpresa,
          imageSarApp: base64,
          groupCode,
          logo: base64Logo,
          urlPortalCliente: clientSiteUrl
        } :
        {
          groupName,
          imageSarApp: base64,
          groupCode,
          logo: base64Logo,
          urlPortalCliente: clientSiteUrl
        };


      try {
        const groupResponse = await api.directTo(apiUrl).post(route, groupData, config);
        if (groupResponse.status === 200) {
          setCurrentGroup(groupData);
          groupData.grupoEmpresa = groupResponse.data.grupoEmpresa;
          groupData.apps = [];
          groupData.empresas = [];
          groupData.imageSarApp = base64;

          for (let selectedApp of selectedApps) {

            let appData = {
              aplicativo: selectedApp.aplicativo,
              datavalidade: selectedApp.dataValidade.replaceAll("-", ""),
              grupoempresa: groupResponse.data.grupoEmpresa
            }


            const licensedAppsResponse = await api.directTo(apiUrl).post('/setAppAndValid', appData, config);

            if (licensedAppsResponse.data.status !== 0) {
              return;
            }

            groupData.apps.push({ ...appData, datavalidade: selectedApp.dataValidade });
          }

          setLicensedApps([...selectedApps]);




          setIsSubmitting(false);
          handleNewGroupInsertion(true);

          await showModal('success');

          if (groupToUpdate) {
            StoragedList.updateGroup(groupData);
          } else {
            const formattedGroup = GeneralUtils.formatGroupObject(groupData);
            selectGroup(formattedGroup);
            StoragedList.setGroup(groupData);
          }

        } else {
          showModal('error');
        }

      } catch {
        showModal('error');
        return;
      } finally {
        setIsSubmitting(false);
      }

    }
  }

  function handleAppRemoval(appId) {
    if (groupToUpdate) {
      setAppToRemove(appId);
    } else {
      removeAppFromSelectedList(appId);
    }

  }

  function removeAppFromSelectedList(appId) {
    const newSelectedAppsArray = selectedApps.filter(app => app.aplicativo !== appId);
    const newAppOptions = appOptions.concat(selectedApps.find(app => app.aplicativo === appId));
    setAppOptions([...newAppOptions]);
    setSelectedApps([...newSelectedAppsArray]);
  }


  async function deleteApp(app, group) {
    let appData = {
      grupoEmpresa: group,
      aplicativo: app
    }

    const response = await api.directTo(apiUrl).post('/deleteApp', appData, config);

    if (response.data.status === 0) {
      const updatedLicensedApps = licensedApps.filter(licensedApp => parseInt(licensedApp.aplicativo) !== parseInt(app));
      setLicensedApps([...updatedLicensedApps])
      StoragedList.deleteApp(app, group);
      removeAppFromSelectedList(app);
      setAppToRemove(null);
      return true;
    } else {
      return false;
    }
  }

  function areValuesValid() {
    if (groupName === '' || selectedApps.length === 0 || !groupCode) {
      setErrors({
        groupName: groupName === '',
        groupCode: !groupCode,
        apps: selectedApps.length === 0
      })
      return false;
    } else {
      return true;
    }
  }


  function resetSelectedApps() {
    setAppOptions(apps);
    setSelectedApps([]);
  }




  async function handleSarAppImage(event) {
    const _base64 = await Utils.fileToBase64(event.target);
    const objSizes = await Utils.extractImageSize(_base64);
    if (objSizes.width !== 1024 || objSizes.height !== 576) {
      return alert("A imagem deve ter o tamanho de 1024 x 576")
    }
    setBase64(_base64);
    window.scrollTo({ top: document.body.scrollHeight, behavior: 'smooth' });

    if (!isSaveButtonVisible) {
      setIsSaveButtonVisible(true);
    }
  }



  async function handleLogoImage(event) {
    const _base64 = await Utils.fileToBase64(event.target);
    const objSizes = await Utils.extractImageSize(_base64);
    if (objSizes.width !== 50 || objSizes.height !== 50) {
      return alert("A imagem deve ter o tamanho de 50px x 50px conforme descrição.")
    }
    setBase64Logo(_base64);
    if (!isSaveButtonVisible) {
      setIsSaveButtonVisible(true);
    }
  }


  function deleteSarAppImage() {
    setBase64(null);
    setIsSaveButtonVisible(true);
    window.scrollTo({ top: document.body.scrollHeight, behavior: 'smooth' });
  }


  function handleClearEdition() {
    clearGroupSelection();
    resetForm();
  }

  function resetForm() {
    setGroupName('');
    setAppOptions(apps);
    setSelectedApps([]);
    setBase64(null);
  }


  function showGroupPicker() {
    setIsGroupPickerVisible(true);
  }

  function hideGroupPicker() {
    setIsGroupPickerVisible(false);
  }

  function pickGroup(group) {
    selectGroup(group);
    setIsSaveButtonVisible(false)
    hideGroupPicker();
  }


  function showCompanySignUpForm() {
    setIsCompanySignUpFormVisible(true);
  }

  function hideCompanySignUpForm() {
    setIsCompanySignUpFormVisible(false);
  }


  function showCompanyEditForm(company) {
    selectCompany(company);
    setIsCompanySignUpFormVisible(true);

  }

  async function reloadCompanies() {
    try {
      setIsLoading(true);
      const result = await api.directTo(apiUrl).get(`/getAllGroups/${groupToUpdate.grupoEmpresa}`, config);
      if (result.status === 200) {
        setCompanies(result.data.data[0].empresas);
      }
    } catch (e) {
      return;
    } finally {
      setIsLoading(false);
    }
  }




  function openUserSefip(userObj) {
    setUserSefip({ show: true, userData: userObj });
  }

  function closeModalUserSefip() {
    getUsersSefip();
    setUserSefip({ show: false, userData: null })
  }



  return (

    <>
      {isAppModalVisible &&
        <AppSelectionModal
          handleAppAdd={handleAppAdd}
          handleAppUpdate={handleAppUpdate}
          closeModal={handleAppModalDisplay}
          apps={appOptions}
          appToEdit={appToEdit}
        />
      }

      {
        showDialogConfirm.show && <ConfirmDialog
          handleCancel={showDialogConfirm.handleCancel}
          handleConfirm={showDialogConfirm.handleConfirm}
          message={showDialogConfirm.message}
          title={showDialogConfirm.title}
        />
      }

      {userSefip.show && <UserSefipForm handleClose={closeModalUserSefip} userData={userSefip.userData} edit={userSefip.userData} codGrupo={currentGroup.grupo_code} />}

      {appToRemove &&
        <AppExclusionModal
          deleteApp={() => deleteApp(appToRemove, groupToUpdate.grupoEmpresa)}
          closeModal={() => setAppToRemove(null)}
          showResponseModal={(result) => showModal(result)}
        />
      }

      {isGroupPickerVisible &&
        <GroupPicker
          handleItemClick={pickGroup}
          closeModal={hideGroupPicker}
        />
      }

      {isCompanySignUpFormVisible &&
        <CompanySignUpForm
          companyToUpdate={companyToUpdate}
          group={groupToUpdate}
          licensedApps={licensedApps}
          closeModal={hideCompanySignUpForm}
          prevPageReload={reloadCompanies}
        />
      }

      {isLoading &&
        <LoadingModal />}

      <SignUpForm>

        <RowSection
          margin={'0 0'}
        >
          <SignUpFormFieldLabel
            htmlFor='name'
            isInsideARow
            bigger
          >
            Grupo
          </SignUpFormFieldLabel>



          {groupToUpdate ?
            <div style={{ display: 'flex', gap: 20 }}>
              <button onClick={deleteGrupo} style={{ color: '#fff', border: 'none', backgroundColor: '#da2c38', borderRadius: 4, padding: '8px 12px' }}>
                Deletar Grupo
              </button>

              <LabelledButton
                src="assets/add.png"
                label="Grupo"
                handleClick={handleClearEdition}
                align={'right'}
              />
            </div> :
            <IconButton
              onClick={showGroupPicker}
            >
              <AiOutlineSearch
                size={25}
                color={'rgba(0,0,0,0.5)'}
              />
            </IconButton>
          }
        </RowSection>




        <SignUpFormField
          type='text'
          placeholder='Informe o nome do grupo...'
          onChange={event => handleNameChange(event.target.value)}
          value={groupName}
          id='name'
          bigger
        />
        {errors.groupName ? <Error>Nome inválido</Error> : null}

        <SimpleGrid template={"1fr 4fr"}>
          <GridItemContainer>
            <SignUpFormFieldLabel bigger>
              Código do grupo
            </SignUpFormFieldLabel>
            <SignUpFormField
              onChange={event => handleGroupCodeChange(event.target.value)}
              value={groupCode}
            />
            {errors.groupCode ? <Error>Insira o código do grupo</Error> : null}
          </GridItemContainer>

          <GridItemContainer>
            <SignUpFormFieldLabel bigger>
              URL API Portal Cliente
            </SignUpFormFieldLabel>
            <SignUpFormField
              onChange={event => handleClientSiteUrlChange(event.target.value)}
              value={clientSiteUrl}
            />


          </GridItemContainer>
        </SimpleGrid>

        <SimpleGrid template={"1fr"}>
          <SignUpFormFieldLabel bigger>
            Logo (50px x 50px)
          </SignUpFormFieldLabel>

          <InputImage
            ref={logoInputRef}
            type='file'
            name='bgImage2'
            id='bgImage2'
            accept="image/png, image/jpeg"
            onChange={handleLogoImage}
          />

          <LogoBox onClick={() => logoInputRef.current.click()}>
            {base64Logo && <img src={base64Logo} style={{ maxWidth: "100%", maxHeight: "100%" }} alt={'Logo'} />}
          </LogoBox>
        </SimpleGrid>

        <SimpleGrid>
          <SpecialInputContainer>
            <SignUpFormFieldLabel htmlFor="app" bigger>Aplicativos licenciados</SignUpFormFieldLabel>

            {selectedApps.length > 0 ? (

              <SelectedAppList>
                {selectedApps.map(app => (
                  <SelectedApp
                    name={app.descricao}
                    date={Format.dateYMDtoDMY(app.dataValidade)}
                    removeApp={() => handleAppRemoval(app.aplicativo)}
                    openAppEdition={() => handleAppEdit(app)}
                    key={app.aplicativo}
                  />
                ))}

              </SelectedAppList>
            ) : (
              <>
                <PlaceholderSquare
                  haserrors={errors.apps}
                >
                  <span>Nenhum aplicativo selecionado</span>
                </PlaceholderSquare>
                {errors.apps ? <Error>Adicione algum app</Error> : null}
              </>
            )
            }


            {selectedApps.length < apps.length &&
              <AddLink
                onClick={handleAppModalDisplay}
                role='button'
              >
                Adicionar App
              </AddLink>
            }
          </SpecialInputContainer>

          <SpecialInputContainer>
            <SectionBGSarApp>
              <InputImage
                type='file'
                name='bgImage'
                id='bgImage'
                accept="image/png, image/jpeg"
                onChange={handleSarAppImage}
              />
              <SignUpFormFieldLabel bigger>Imagem SarApp</SignUpFormFieldLabel>

              <BoxImageGroup wasImageUploaded={!!base64}>
                {base64 ? <img src={base64} style={{ maxWidth: "100%", maxHeight: "100%" }} alt={'...'} /> : <p>Nenhuma imagem inserida</p>}
              </BoxImageGroup>
            </SectionBGSarApp>

            <RowSection>
              <FileLink htmlFor="bgImage">
                {base64 ? "Trocar imagem" : "Adicionar imagem"}
              </FileLink>

              {base64 && <AddLink onClick={deleteSarAppImage} color="#e63946">
                Apagar imagem
              </AddLink>}
            </RowSection>
          </SpecialInputContainer>
        </SimpleGrid>


        <Divider
          margin={'2rem 0'}
        />



        {groupToUpdate &&
          <>
            <Divider
              margin={'2rem 0'}
            />
            <RowSection
              margin={'0 0 10px'}
            >
              <TableTitle>
                Empresas
              </TableTitle>

              <LabelledButton
                src="assets/add.png"
                label="Empresa"
                handleClick={showCompanySignUpForm}
                align={'right'}
              />
            </RowSection>
          </>
        }

        {groupToUpdate && companies.length > 0 &&
          <CompanyTable
            companies={companies}
            handleRowClick={showCompanyEditForm}
            deleteCompany={deleteCompany}
          />
        }


        {groupToUpdate && companies.length === 0 &&
          <PlaceholderSquare>
            <span>Nenhuma empresa cadastrada</span>
          </PlaceholderSquare>
        }

        <>
          <Divider
            margin={'2rem 0'}
          />
          <RowSection
            margin={'0 0 10px'}
          >
            <TableTitle>
              Usuários Separador SEFIP
            </TableTitle>

            <LabelledButton
              src="assets/add.png"
              label="Usuário"
              handleClick={() => openUserSefip(null)}
              align={'right'}
            />
          </RowSection>
        </>



        {<UsersSefipTable users={allUseresSefip} handleRowClick={openUserSefip} />}


        {isSaveButtonVisible &&
          <Button
            onClick={handleGroupSubmit}
            disabled={isSubmitting}
            align={'flex-start'}
            aria-label='save-group'
          >
            {isSubmitting ?
              <Loader
                type="ThreeDots"
                color="#00BFFF"
                height={10}
                width={20}
              />
              : 'SALVAR'}
          </Button>}



      </SignUpForm>
    </>
  )

}

export default GroupSignUpForm;